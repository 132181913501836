<template>
  <!-- <div class="grid grid-cols-3 gap-4">
    <div 
      v-for="(item, index) in obj" :key="index" :obj="item" 
      class="relative rounded-md overflow-hidden bg-gray-800 text-gray-300 p-4 cursor-pointer group hover:bg-gray-900 transition duration-300 ease-in-out"
    >
      <div class="relative w-full pt-68%">
        <img :src="globals.data.pages[item].featured_image + '?key=sm'" class="object-cover absolute inset-0 w-full h-full" />
      </div>
      <div class="mt-2 text-xl">
        {{globals.data.pages[item].title}}
      </div>
      <div class="mt-2 text-sm" v-html="globals.data.pages[item].description">
      </div>
      <a :href="globals.data.pages[item].path" class="absolute inset-0"></a>
    </div>
  </div> -->

  <div class="text-center pb-5" v-if="mode != 'overview'">
    <div class="font-delta inline-block p-5">
      <span v-if="year">
        {{year}}
      </span>
      <span v-else>
        {{globals.page_cur.year}}
      </span>
    </div>
  </div>

  <div class="text-center pb-5" v-if="mode != 'overview'">
    <div class="font-delta inline-block p-5">
      {{year_use}}
    </div>
  </div>

  <div class="grid grid-cols-7 gap-4 justify-items-center mb-10 font-epilogue_r">
    <a v-for="(item, index) in obj" :href="'/' + slug_use + '/' + item" :key="index" class="week-ball rounded-full bg-matt text-white relative w-28 h-28 hover:bg-amaranth transition duration-300 ease-in-out  mx900:w-14 mx900:h-14 mx640:w-8 mx640:h-8">
      <p class=" pos-center mn640:text-2xl">
        {{(Number(index)+1)}}
      </p>
      <span class="centerx -bottom-2 bg-amaranth h-1 w-1/2 rounded mn900:-bottom-3" v-if="currentWeekNumber == Number(index)+1"></span>
    </a>
    <a v-for="(item, index) in weeksLeft" :key="index" class="week-ball rounded-full bg-matt text-white relative w-28 h-28 mx900:w-14 mx900:h-14 mx640:w-8 mx640:h-8 opacity-50">
      <p class=" pos-center mn640:text-2xl">
        {{(Number(index)+1+weeksUsed)}}
      </p>
      <span class="centerx -bottom-2 bg-amaranth h-1 w-1/2 rounded mn900:-bottom-3" v-if="currentWeekNumber == Number(index)+1+weeksUsed"></span>
    </a>
  </div>
</template>

<script>

export default {
  props: ['obj','mode','year'],
  components: {
  },
  data(){
    return{
      weeksLeft: 0,
      weeksUsed: 0,
      currentWeekNumber: 0,
      year_use: 0,
      slug_use: ''
    }
  },
  mounted(){
    this.setStoriesCalendar()
    this.yearMatch()
  },
  methods:{
    setStoriesCalendar(){
      this.weeksUsed = Object.keys(this.obj).length;
      this.weeksLeft= 52 - this.weeksUsed;

      this.currentWeekNumber = 0
      if(this.$props.year == this.globals.data.date_info.current_year){
        this.currentWeekNumber = this.globals.data.date_info.current_week_number
      }
    },
    yearMatch(){
      let src = ''
      if(this.$props.year){
        src = 'overview'
        this.year_use = this.$props.year
      }else{
        src = 'direct'
        this.year_use = this.globals.page_cur.year
      }

      for (const [key] of Object.entries(this.globals.data.pages)) {
        if(this.globals.data.pages[key].year == this.year_use){
          this.slug_use = key
        }
      }
      
      //console.log('stories calendar: ' + this.year_use + '( ' + src + ' )')
    }
  },
  watch: { 
    obj: function(newVal, oldVal) { 
      this.setStoriesCalendar()
      this.yearMatch()
    }
  }
}
</script>

<style scoped>
/* .week-ball::after{
  content: " ";
  border-bottom-style: solid;
  border-color: #D32232;
  border-bottom-width:2px;
  display:block;
  width:30px;
  margin: 0 auto;
} */
</style>
