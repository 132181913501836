<template>
  <!-- <div class="w-full h-full rounded-lg overflow-hidden relative">
    <video class="h-full w-full object-cover filter blur-sm absolute">
      <source :src="item.video" type="video/mp4">
      Your browser does not support HTML video.
    </video>
    <video playsinline  controls class="h-full w-full z-10 relative">
      <source :src="item.video" type="video/mp4">
      Your browser does not support HTML video.
    </video>
  </div> -->
  <div class="w-full h-full rounded-lg overflow-hidden">
    <div class="plyr__video-embed rounded-lg h-full" id="player_youtube" v-if="item.video_youtube">
      <iframe
        :src="'https://www.youtube.com/embed/' + item.video_youtube + '?iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1'"
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></iframe>
    </div>

    <div class="plyr__video-embed rounded-lg h-full" id="player_vimeo" v-else-if="item.video_vimeo">
      <iframe
        :src="'https://player.vimeo.com/video/' + item.video_vimeo + '?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media'"
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></iframe>
    </div>

    <video id="player_video" class="rounded-lg h-full" playsinline controls v-else-if="item.video">
      <source :src="item.video" type="video/mp4" />
    </video>

  </div>
</template>

<script>

import Plyr from 'plyr';

export default {
  props: ['item'],
  components: {
  },
  mounted(){
    const player_youtube = new Plyr('#player_youtube');
    const player_vimeo = new Plyr('#player_vimeo');
    const player_video = new Plyr('#player_video');
  },
  methods:{
  }
}
</script>

<style>
@import url('https://cdn.plyr.io/3.6.9/plyr.css');
.plyr{
  height: 100% !important;
}
.plyr__video-wrapper{
  background:white !important;
}
</style>
