<template>
  <div class="pt-44 mx900:pt-32 pb-400px mx900:pb-650px">
    <!-- <div class="rounded-md p-6 bg-gray-700 text-gray-200"> -->
      <div v-if="obj.type === 'page'">
        <Blocks v-for="(item, index) in obj.blocks" :key="index" :obj="item" />
      </div>

      <div v-if="obj.type === 'story'">
        <div id="bg-body" class="bg-magnitude w-full top-0 absolute"></div>
        <div class="mode-center">
          <div class="border-b border-black mb-4 body-rich-text">
            <h5 class="font-epilogue_r">
              <WeekString />
            </h5>
            <h1 class="font-delta">{{obj.title}}</h1>
          </div>
          <!-- <StoryBlocks v-for="(item, index) in obj.blocks" :key="index" :obj="blocks" /> -->
          <StoryBlocks_copy :obj="obj.blocks" />

          <MoreInfo :obj="obj"/>

          <WeekNav />
        </div>
      </div>

      <div v-if="obj.type === 'years'">
        <div class="mode-center">
          <Years :obj="obj" />
        </div>
      </div>

    </div>
  <!-- </div> -->
</template>

<script>
import Blocks from '@/components/Blocks'
// import StoryBlocks from '@/components/StoryBlocks'
import StoryBlocks_copy from '@/components/StoryBlocks_copy'
import MoreInfo from '@/components/ui/MoreInfo'
import Years from '@/components/Years'
import WeekNav from '@/components/ui/WeekNav.vue'
import WeekString from '@/components/ui/WeekString.vue'

var page_dest, page_src, crumbs

export default {
  props: ['obj'],
  components: {
    Blocks,
    // StoryBlocks
    StoryBlocks_copy,
    MoreInfo,
    Years,
    WeekNav,
    WeekString
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style scoped>
</style>
