<template>
  <div>
    <!-- <div class="border-b border-dashed opacity-25 pb-1 my-6" v-if="globals.section_tool">
      {{obj.type}} 
    </div> -->

    <Slide_backup :items="obj" />

  </div>
</template>

<script>

import Slide_backup from '@/components/ui/Slide_backup.vue'

export default {
  props: ['obj'],
  components: {
    Slide_backup
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style scoped>
</style>
