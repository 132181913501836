<template>
  <div class="w-full h-full rounded-lg p-6 overflow-hidden bg-white">
    <!-- <div v-html="item.intro" class="intro-rich-text story-rich-text"></div> -->
    <div v-html="item.intro" class="story-rich-text"></div>
  </div>
</template>

<script>

export default {
  props: ['item'],
  components: {
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style scoped>
  .intro-rich-text{
    @apply text-black;
  }
  .intro-rich-text a{
    @apply block mb-4 underline;
  }
</style>
