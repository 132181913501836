<template>
  <div class="body-rich-text">
    <div v-html="obj.text" class="mb-12 titles-rich-text">
    </div>
  </div>
</template>

<script>

export default {
  props: ['obj'],
  components: {
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style>
</style>
