<template>
  <div class="relative">
    <swiper 
    :breakpoints="swiperOptions.breakpoints"
    class="slides-wrapper absolute top-0 left-0"
    >
      <swiper-slide class="relative" v-for="(item, index) in obj.gallery" :key="index" :obj="item">
        <div class="relative w-full pt-68%">
          <img :src="item + '?key=sm'" class="object-cover absolute inset-0 w-full h-full" />
        </div>
      </swiper-slide>
    </swiper>

  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js'
import "swiper/swiper.min.css"
import SwiperCore, {
  Navigation,
  Pagination,
  EffectCoverflow,
} from "swiper"


export default {
  props: ['obj'],
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return{
      swiperOptions:{
        mousewheel: true,
        freeMode: true,
        breakpoints: {
          250: {
            slidesPerView: "2",
            spaceBetween: 10,
          },
          640: {
            slidesPerView: "3",
            spaceBetween: 10
          },
          900: {
            slidesPerView: "3",
            spaceBetween: 20
          }
        },
      }
    }
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style scoped>
@media screen and (max-width:900px) {
  .slides-wrapper{
    width: 120%;
  }
}
</style>
