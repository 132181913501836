<template>
  <div>

    <div class="border-b border-dashed opacity-25 pb-1 my-6" v-if="globals.section_tool">
      {{obj.type}} 
    </div>

    <Text v-if="obj.type === 'text'" class="mode-center" :obj="obj.content" />
    <Cta v-if="obj.type === 'cta'" :obj="obj.content" />
    <Gallery v-if="obj.type === 'gallery'" class="mode-center" :obj="obj.content" />

    <Stories v-if="obj.type === 'stories'" :obj="obj.content" />

  </div>
</template>

<script>
import Text from '@/components/blocks/Text'
import Cta from '@/components/blocks/Cta'
import Gallery from '@/components/blocks/Gallery'
import Stories from '@/components/blocks/Stories'

export default {
  props: ['obj'],
  components: {
    Text, Cta, Gallery, Stories
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style scoped>
</style>
