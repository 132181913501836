<template>
  <div class="w-full h-full bg-perfume flex items-end rounded-lg p-6 overflow-hidden">
    <div class="">
      <div v-html="item.overview_text" class="story-rich-text overview-rich-text mb-6">
      </div>
      <div class="bg-white font-epilogue_r p-3 rounded-28 text-sm cursor-pointer w-full hover:bg-magnitude transition duration-300 ease-in-out flex justify-between items-center">
        <router-link :to="parent_slug" class="">
          {{item.overview_button_label}}
        </router-link>
        <ButtonArrow class="w-3 h-3 mn900:w-5 mn900:h-5"/>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonArrow from "../ui/ButtonArrow.vue"
export default {
  props: ['item'],
  components: {
    ButtonArrow
  },
  data() {
    return {
      parent_slug: '/overzicht',
    }
  },
  mounted(){
    this.get_parent_slug()
  },
  updated(){
    this.get_parent_slug()
  },
  methods:{
    get_parent_slug(){
      //this.parent_slug = '/' + this.globals.page_cur.parent
    }
  }
}
</script>

<style>
  .overview-rich-text{
    @apply text-amaranth;
  }
  .overview-rich-text a{
    @apply block underline;
  }
</style>
