<template>
  <!-- <div id="slides-wrapper" class="mx900:hidden mb-10">
    <h1 class="pos-center font-epilogue_r" v-if="itemLength < 1">Er is geen informatie over deze week</h1>
    <swiper
      :key="key"
      :breakpoints="swiperOptions.breakpoints"
      :navigation="navigation"
      :pagination="pagination"
      :centeredSlides="true"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      class="relative h-full"
    >
    <swiper-slide class="slide rounded-lg relative w-auto transition duration-300 ease-in-out"  v-for="(item, index) in items" :key="index" :id="'slide_num_'+(index+1)">
        <Quote v-if="item.type === 'quote'" :item="item.content" />
        <Image v-if="item.type === 'image'" :item="item.content" />
        <Video v-if="item.type === 'video'" :item="item.content" />
        <Text v-if="item.type === 'text'" :item="item.content" :card_id="index+1" />
        <Links v-if="item.type === 'links'" :item="item.content" />
        <Intro v-if="item.type === 'intro'" :item="item.content" />
        <Cta v-if="item.type === 'cta'" :item="item.content" />
        <Overview v-if="item.type === 'overview'" :item="item.content" />
      </swiper-slide>
      <div v-if="itemLength > 1" class="swiper-next bg-white rounded-full absolute top-2/4 z-10 right-2 slide-arrow hover:bg-perfume transition duration-300 ease-in-out cursor-pointer">
        <svg class=" w-16 h-16" xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 9 15" width="9">
          <path d="m7.71845 7.21826.88899.88899c.49098-.49097.49098-1.287 0-1.77798zm-6.748122 4.97014c-.490975.491-.490976 1.287 0 1.778.490972.491 1.287002.491 1.777982 0zm1.777982-11.718262c-.49098-.4909762-1.28701-.4909764-1.777982-.000001-.490976.490976-.490975 1.287003 0 1.777983zm4.08115 5.859132-5.859132 5.85913 1.777982 1.778 5.85913-5.85915zm-5.859132-4.08115 5.859132 5.85913 1.77798-1.77798-5.85913-5.859132z" fill="#000" />
        </svg>
      </div>
      <div v-if="itemLength > 1" class="swiper-prev bg-white rounded-full absolute top-2/4 z-10 left-2 slide-arrow hover:bg-perfume transition duration-300 ease-in-out cursor-pointer">
        <svg class="w-16 h-16 transform rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 9 15" width="9">
          <path d="m7.71845 7.21826.88899.88899c.49098-.49097.49098-1.287 0-1.77798zm-6.748122 4.97014c-.490975.491-.490976 1.287 0 1.778.490972.491 1.287002.491 1.777982 0zm1.777982-11.718262c-.49098-.4909762-1.28701-.4909764-1.777982-.000001-.490976.490976-.490975 1.287003 0 1.777983zm4.08115 5.859132-5.859132 5.85913 1.777982 1.778 5.85913-5.85915zm-5.859132-4.08115 5.859132 5.85913 1.77798-1.77798-5.85913-5.859132z" fill="#000" />
        </svg>
      </div>
    </swiper>
  </div> -->
  <div id="slides-wrapper-mob" class="relative">
    <h1 class="pos-center font-epilogue_r" v-if="itemLength < 1">Er is geen informatie over deze week</h1>
    <swiper
      :key="key"
      :breakpoints="swiperOptions.breakpoints"
      :slidesPerView="swiperOptions.slidesPerView"
      :swiperOption="swiperOptions"
      :navigation="navigation"
      :pagination="pagination"
      :centeredSlides="true"
      :threshold="10"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      class="relative mb-14">
      <swiper-slide class="slide-mobile rounded-lg relative transition duration-300 ease-in-out"  v-for="(item, index) in items" :key="index" :id="'slide_num_'+(index+1)">
        <Quote v-if="item.type === 'quote'" :item="item.content" />
        <Image v-if="item.type === 'image'" :item="item.content" />
        <Video v-if="item.type === 'video'" :item="item.content" />
        <Text v-if="item.type === 'text'" :item="item.content" :card_id="'mob_'+(index+1)" />
        <Links v-if="item.type === 'links'" :item="item.content" />
        <Intro v-if="item.type === 'intro'" :item="item.content" />
        <Cta v-if="item.type === 'cta'" :item="item.content" />
        <Overview v-if="item.type === 'overview'" :item="item.content" />
      </swiper-slide>
      <div v-if="itemLength > 1" class="swiper-next bg-white rounded-full absolute top-2/4 z-10 right-2 slide-arrow hover:bg-perfume transition duration-300 ease-in-out cursor-pointer">
        <svg class="" xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 9 15" width="9">
          <path d="m7.71845 7.21826.88899.88899c.49098-.49097.49098-1.287 0-1.77798zm-6.748122 4.97014c-.490975.491-.490976 1.287 0 1.778.490972.491 1.287002.491 1.777982 0zm1.777982-11.718262c-.49098-.4909762-1.28701-.4909764-1.777982-.000001-.490976.490976-.490975 1.287003 0 1.777983zm4.08115 5.859132-5.859132 5.85913 1.777982 1.778 5.85913-5.85915zm-5.859132-4.08115 5.859132 5.85913 1.77798-1.77798-5.85913-5.859132z" fill="#000" />
        </svg>
      </div>
      <div v-if="itemLength > 1" class="swiper-prev bg-white rounded-full absolute top-2/4 z-10 left-2 slide-arrow hover:bg-perfume transition duration-300 ease-in-out cursor-pointer">
        <svg class="transform rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" height="15" viewBox="0 0 9 15" width="9">
          <path d="m7.71845 7.21826.88899.88899c.49098-.49097.49098-1.287 0-1.77798zm-6.748122 4.97014c-.490975.491-.490976 1.287 0 1.778.490972.491 1.287002.491 1.777982 0zm1.777982-11.718262c-.49098-.4909762-1.28701-.4909764-1.777982-.000001-.490976.490976-.490975 1.287003 0 1.777983zm4.08115 5.859132-5.859132 5.85913 1.777982 1.778 5.85913-5.85915zm-5.859132-4.08115 5.859132 5.85913 1.77798-1.77798-5.85913-5.859132z" fill="#000" />
        </svg>
      </div>
    </swiper>
  </div>
</template>
<script>
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
  import Quote from '@/components/story_blocks/Quote';
  import Image from '@/components/story_blocks/Image';
  import Video from '@/components/story_blocks/Video';
  import Text from '@/components/story_blocks/Text';
  import Links from '@/components/story_blocks/Links';
  import Intro from '@/components/story_blocks/Intro';
  import Cta from '@/components/story_blocks/Cta';
  import Overview from '@/components/story_blocks/Overview';
  import "swiper/swiper.min.css";
  import SwiperCore, {
    Navigation,
    Pagination,
    EffectCoverflow,
  } from "swiper";
  SwiperCore.use([Navigation, Pagination, EffectCoverflow]);
  

  export default {
    components: {
      Swiper,
      SwiperSlide,
      Quote,
      Image,
      Video,
      Text,
      Links,
      Intro,
      Cta,
      Overview,
    },
    props: ["items"],
    data(){
      return{
        key: 0
      }
    },
    beforeUpdate(){
      this.key ++;
    },
    updated(){
      this.checkPagination()
    },
    mounted(){
      this.checkPagination();
    },
    computed:{
      itemLength: function () {
      return Object.keys(this.items).length
      }
    },
    methods:{
      checkPagination(){
        if(this.itemLength <= 1){
          document.getElementsByClassName("swiper-pagination")[0].style.display = "none"
          // document.getElementsByClassName("swiper-pagination")[1].style.display = "none"
        }else{
          document.getElementsByClassName("swiper-pagination")[0].style.display = "flex"
          // document.getElementsByClassName("swiper-pagination")[1].style.display = "flex"
        }
      }
    },
    setup() {
      const onSwiper = (swiper) => {
      };
      const onSlideChange = () => {
      };
      return {
        onSwiper,
        onSlideChange,
        textCardSelected:0,
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
        pagination: {
          clickable: true,
          renderBullet: function (index, className) {
            return `<span class="${className}"></span>`;
          },
        },
        swiperOptions: {
          breakpoints: {
            250: {
              slidesPerView: "auto",
              spaceBetween: 10
            },
            480: {
              slidesPerView: "auto",
              spaceBetween: 10
            },
            900: {
              slidesPerView: "auto",
              spaceBetween: 20
            },
          },
        },
      };
    },
  };
</script>

<style scoped>
  #slides-wrapper{
    height: 90vh;
  }
  #slides-wrapper-mob{
    min-height: 480px;
  }
  .slide-mobile {
    height: 480px;
    width: 270px !important;
  }
  .swiper-slide{
    box-shadow: 0px 0px 5.625px rgba(0, 0, 0, 0.25);
  }
  .slide-arrow{
    padding: 5px 8px;
  }
  .swiper{
    padding-bottom: 20px;
    overflow: unset;
  }
  .swiper-button-disabled{
    display: none;
  }
  .swiper-slide{
    transform: scaleY(0.95) scaleX(0.95);
    margin: 0;
    box-shadow: 0px 0px 5.625px rgba(0, 0, 0, 0.25);
  }
  .swiper-slide-active{
    transform: scaleY(1) scaleX(1);
  }
</style>
