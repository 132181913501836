<template>
  <div class="py-4 text-white bg-amaranth mn900:py-24">
    <div class="mode-center mn900:grid mn900:grid-cols-3 mn900:items-center mn900:gap-10">
      <div v-html="obj.cta_text" class="body-rich-text cta-rich-text grid col-span-2 mn900:grid-cols-2 mn900:items-center mn900:gap-10">
      </div>
      <div class="bg-white font-epilogue_r p-3 mt-10 mn900:my-5 mb-5 rounded-28 text-sm cursor-pointer flex justify-between items-center hover:bg-perfume transition duration-300 ease-in-out" v-if="obj.cta_button_link">
        <a :href="obj.cta_button_link" target="_blank" class="py-2 px-4 text-black">
          {{obj.cta_button_label}}
        </a>
        <ButtonArrow class="w-3 h-3 mn900:w-5 mn900:h-5"/>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonArrow from "../ui/ButtonArrow.vue"
export default {
  props: ['obj'],
  components: {
    ButtonArrow
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style>
.cta-rich-text h1, .cta-rich-text h2,.cta-rich-text h3{
  color: #F8F4E9 !important;
}
</style>
