<template>
  <div class="w-full h-full bg-amaranth rounded-lg p-6 overflow-hidden">
    <div v-html="item.links" class="story-rich-text link-rich-text">
    </div>
  </div>
</template>

<script>

export default {
  props: ['item'],
  components: {
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style>
  .link-rich-text h1, .link-rich-text h2, .link-rich-text h3, .link-rich-text h4{
    @apply text-perfume !important;
  }
  .link-rich-text p{
    @apply text-magnitude !important;
  }
  .link-rich-text a{
    @apply underline;
  }
</style>
