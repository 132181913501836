<template>
<div class="w-full h-full rounded-lg overflow-hidden bg-white">
  <img :src="item.image" class="w-full h-full object-contain" />
</div>
  <!-- <div class="p-4 rounded bg-gray-800">
    <img :src="obj.image + '?key=lg'" class="mb-6" />
    <div class="text-sm text-gray-400">
      {{obj.image_caption}}
    </div>
  </div> -->
</template>

<script>

export default {
  props: ['item'],
  components: {
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style scoped>
</style>
