<template>
<div class="w-full h-full bg-treasure rounded-lg p-6 overflow-hidden">
  <div class="flex flex-col justify-between h-full">
    <div v-html="item.cta_text" class="cta-rich-text story-rich-text">
    </div>
    <div class="bg-white font-epilogue_r p-3 rounded-28 text-sm cursor-pointer w-full flex justify-between items-center hover:bg-perfume transition duration-300 ease-in-out" v-if="item.cta_button_link">
      <a :href="item.cta_button_link" target="_blank" class="">
        {{item.cta_button_label}}
      </a>
      <ButtonArrow class="w-3 h-3"/>
    </div>
  </div>
</div>
</template>

<script>
import ButtonArrow from "../ui/ButtonArrow.vue"
export default {
  props: ['item'],
  components: {
    ButtonArrow
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style>
.cta-rich-text h1, .cta-rich-text h2, .cta-rich-text h3, .cta-rich-text h4{
  @apply text-perfume 
}
.cta-rich-text{
  @apply text-white;
}
.cta-rich-text a{
  @apply block underline;
}
</style>
