<template>
  <div>

    <div class="border-b border-dashed opacity-25 pb-1 my-6" v-if="globals.section_tool">
      overview [no block]
    </div>

    <div class="flex items-center mb-8">
      <div class="font-epilogue_r p-5 cursor-pointer text-lg mn900:text-2xl mn900:text-treasure" @click="setStories(year_prev)">
        {{year_prev}}
      </div>
      <div class="ml-auto"></div>
      <div class="font-delta p-5 cursor-pointer text-2xl mn900:text-8xl mn900:text-treasure" @click="setStories(year_show)">
        {{year_show}}
      </div>
      <div class="ml-auto"></div>
      <div class="font-epilogue_r p-5 cursor-pointer text-lg mn900:text-2xl mn900:text-treasure" @click="setStories(year_next)">
        {{year_next}}
      </div>
    </div>

    <Stories v-if="stories" :obj="stories" mode="overview" :year="year" />

  </div>
</template>

<script>
import Stories from '@/components/blocks/Stories'

export default {
  props: ['obj'],
  components: {
    Stories
  },
  data(){
    return{
      stories: null,
      year: null,
      years_total: 0,
      year_show: '',
      year_prev: '',
      year_next: '',
    }
  },
  mounted(){
    this.setStories(this.globals.data.date_info.current_year)
    //this.setStories('2022')
    this.years_total = Object.keys(this.$props.obj.years).length
  },
  methods:{
    setStories(year){
      //console.log(year)
      this.year = year
      this.year_show = year
      this.year_prev = ''
      this.year_next = ''
      var year_prev = Number(year) - 1
      var year_next = Number(year) + 1
      var key
      for (key of Object.keys(this.globals.data.date_info.years)) {
          if(this.globals.data.date_info.years[key].year == year_prev){
            this.year_prev = String(year_prev)
          }
      }
      for (key of Object.keys(this.globals.data.date_info.years)) {
          if(this.globals.data.date_info.years[key].year == year_next){
            this.year_next = String(year_next)
          }
      }
      for (key of Object.keys(this.globals.data.pages)) {
          if(this.globals.data.pages[key].type === 'page'){
            if(this.globals.data.pages[key].year === year){
              this.stories = this.globals.data.pages[key].blocks[0].content
            }
          }
      }
    }
  }
}
</script>

<style scoped>
</style>
