<template>

  <div class="flex justify-between border-t border-black py-4 font-epilogue_r">
    <div class="relative" :class="{ 'opacity-30': prev_disabled, 'invisible' : week_nr == 0, 'group-menu' : !prev_disabled}">
      <div class="flex items-center group-menu-hover:text-perfume text-amaranth transotion duration-300 ease-in-out">
        <WeekNavArrow class="mr-2 transform rotate-180"/>
        <p class="">Vorige week</p>
      </div>
      <a :href="path_prev" class="absolute inset-0 cursor-pointer" v-show="!prev_disabled"></a>
    </div>
    <div class="ml-auto"></div>
    <div class="relative" :class="{ 'opacity-30': next_disabled, 'invisible' : week_nr == 0, 'group-menu' : !next_disabled}">
      <div class="flex items-center text-amaranth group-menu-hover:text-perfume transotion duration-300 ease-in-out">
        <p class="mr-2">Volgende week</p>
        <WeekNavArrow />
      </div>
      <a :href="path_next" class="absolute inset-0 cursor-pointer" v-show="!next_disabled"></a>
    </div>
  </div>

</template>

<script>
import WeekNavArrow from "./WeekNavArrow.vue"
let route_cur, parent_cur, child_cur
  
export default {
  components:{
    WeekNavArrow
  },
  props: [],
  data() {
    return {
      week_nr: 0,
      week_slug: '',
      week_prev_nr: 0,
      week_prev_slug: '',
      week_next_nr: 0,
      week_next_slug: '',
      weeks_total: 0,
      prev_disabled: true,
      next_disabled: true,
      path_prev: '/',
      path_next: '/'
    }
  },
  mounted() {
    this.weekify()
  },
  methods:{
    weekify(){
      this.week_nr = 0
      this.gsap.delayedCall(0.4, this.weekHandler)
    },
    weekHandler(){
      route_cur = this.$route.path.split('/')
      parent_cur = route_cur[1]
      child_cur = route_cur[2]
      if(!parent_cur || parent_cur == ''){
        this.weekAuto()
      }

      this.weeks_total = 0
      for (const [key, value] of Object.entries(this.globals.data.pages[parent_cur].blocks[0].content)) {
        if(value == child_cur){
          this.week_nr = Number(key) + 1
          this.week_slug = value
          this.week_prev_nr = this.weeks_total
          this.week_prev_slug = this.globals.data.pages[parent_cur].blocks[0].content[this.weeks_total -1]
          this.week_next_nr = this.weeks_total + 2
          this.week_next_slug = this.globals.data.pages[parent_cur].blocks[0].content[this.weeks_total + 1]
        }
        this.weeks_total++
      }
      this.path_prev = '/' + parent_cur + '/' + this.week_prev_slug
      this.path_next = '/' + parent_cur + '/' + this.week_next_slug

      if(this.week_nr == 1){
        this.prev_disabled = true
      }else{
        this.prev_disabled = false
      }
      if(this.week_nr == this.weeks_total){
        this.next_disabled = true
      }else{
        this.next_disabled = false
      }

      /*
      console.log(parent_cur, child_cur)
      console.log(this.globals.data.pages[parent_cur].blocks[0].content)
      console.log('current week nr: ' + this.week_nr)
      console.log('current week slug: ' + this.week_slug)
      console.log('prev week nr: ' + this.week_prev_nr)
      console.log('prev week slug: ' + this.week_prev_slug)
      console.log('next week nr: ' + this.week_next_nr)
      console.log('next week slug: ' + this.week_next_slug)
      console.log('total weeks: ' + this.weeks_total)
      */
    },
    weekAuto(){
      for (const [key] of Object.entries(this.globals.data.pages)) {
        if(this.globals.data.pages[key].year == this.globals.data.date_info.current_year){
          parent_cur = key
        }
      }
      child_cur = this.globals.data.pages[parent_cur].blocks[0].content[Number(this.globals.data.date_info.current_week_number) - 1]
    }
  },
  watch:{
    $route (to, from){
      this.weekify()
    }
  } 
};
</script>

<style>
</style>
