<template>

  <span :class="{ 'invisible' : week_string == '-'}">
    {{week_string}}
  </span>

</template>

<script>
let route_cur, parent_cur, child_cur

export default {
  props: [],
  data() {
    return {
      week_string: '-',
      week_nr: 0
    }
  },
  mounted() {
    this.weekify()
  },
  methods:{
    weekify(){
      this.week_string = '-'
      this.gsap.delayedCall(0.4, this.weekHandler)
    },
    weekHandler(){
      route_cur = this.$route.path.split('/')
      parent_cur = route_cur[1]
      child_cur = route_cur[2]
      if(!parent_cur || parent_cur == ''){
        this.weekAuto()
      }

      for (const [key, value] of Object.entries(this.globals.data.pages[parent_cur].blocks[0].content)) {
        if(value == child_cur){
          this.week_nr = Number(key) + 1
        }
      }
      this.week_string = ' WEEK ' + this.week_nr + ' | ' + this.globals.data.pages[parent_cur].date_info.weeks[this.week_nr]
    },
    weekAuto(){
      for (const [key] of Object.entries(this.globals.data.pages)) {
        if(this.globals.data.pages[key].year == this.globals.data.date_info.current_year){
          parent_cur = key
        }
      }
      child_cur = this.globals.data.pages[parent_cur].blocks[0].content[Number(this.globals.data.date_info.current_week_number) - 1]
    }
  },
  watch:{
    $route (to, from){
      this.weekify()
    }
  } 
};
</script>

<style>
</style>
