<template>

  <div v-if="obj.more_info">
    <div id="more-info-button" @click="moreInfo" class="mb-10 mn900:hidden">
      <div class="bg-magnitude font-epilogue_r p-3 rounded-28 text-sm cursor-pointer mx-auto table hover:bg-perfume transition duration-300 ease-in-out">
        <div class="flex justify-between items-center">
          <span class="mr-2" v-if="obj.more_info_label">{{obj.more_info_label}}</span>
          <span class="mr-2" v-else>Meer info over deze week</span>
          <ButtonArrow id="more-info-arrow" class="w-3 h-3 transform rotate-90"/>
        </div>
      </div>
    </div>
    <div class="mn900:pt-20 pb-10 mn900:w-3/4 mn900:mx-auto">
      <div id="more-info-text" v-if="obj.more_info" v-html="obj.more_info" class="body-rich-text more-info-rich-text invisible h-0 mn900:visible mn900:h-auto">
      </div>
    </div>
  </div>

</template>

<script>
import ButtonArrow from "../ui/ButtonArrow.vue"
export default {
  props: ['obj'],
  components:{
    ButtonArrow
  },
  data() {
    return {
      infoVisible: false
    }
  },
  mounted() {
  },
  methods:{
    moreInfo(){
      const moreInfoText = '#more-info-text'
      const moreInfoArrow = '#more-info-arrow'
      if(!this.infoVisible){
        this.gsap.timeline()
        .to(moreInfoArrow,.3,{
          rotation: "-90deg"
        })
        .to(moreInfoText,.2,{ 
          height: "auto",
        },"-=.2")
        .to(moreInfoText,.3,{ 
          autoAlpha:1,
        },"-=.2")
        .to(window, {duration: .5, scrollTo: {y: "#more-info-button", offsetY: 80}},"-=.2");
      }else{
       this.gsap.timeline()
       .to(moreInfoArrow,.3,{
          rotation: "90deg"
        })
        .to(moreInfoText,.2,{ 
          autoAlpha:0,
        },"-=.3")
        .to(moreInfoText,{ 
          height: 0,
        },"-=.3")
      }
      this.infoVisible = !this.infoVisible
    }
  }
};
</script>

<style>
.more-info-rich-text a, .more-info-rich-text p, .more-info-rich-text ul{
  width: 100% !important;
}
.more-info-rich-text h2{
  @apply text-treasure !important;
}
</style>
