<template>
  <div class="bg-perfume w-full h-full rounded-lg p-6 flex flex-col justify-between overflow-x-hidden overscroll-y-auto break-words">
    <div class="story-rich-text">
      <QuoteSvg class="mb-4" />
      <h2 class="font-delta mb-4 text-amaranth">{{ item.quote_text }}</h2>
      <div class="flex justify-end">
        <QuoteSvg class="transform rotate-180" />
      </div>
    </div>
    <h2 class="text-xl text-amaranth font-epilogue_r">{{ item.quote_subtext }}</h2>
  </div>
</template>

<script>
import QuoteSvg from "@/components/ui/QuoteSvg.vue";
export default {
  props: ['item'],
  components: {
    QuoteSvg
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style scoped>
</style>
