<template>
  <div :id="'text_wrapper_num_'+(card_id)" class="w-full h-full bg-matt rounded-lg p-6 overflow-hidden">
    <div>
      <div v-html="item.text" class="story-rich-text text-rich-text">
      </div>
      <div :id="'gradient_num_'+(card_id)" :class="{ 'text-gradient' : isOverflow }" class="absolute h-1/2 bottom-0 left-0 w-full items-end rounded-lg hidden">
        <div :id="'button_num_'+(card_id)" class="p-6">
          <div @click="extendCardHeight(card_id)" class="bg-white p-3 rounded-28 text-sm cursor-pointer flex justify-between items-center hover:bg-perfume transition duration-300 ease-in-out">
            <p :id="'text_num_'+(card_id)" class="mr-2 font-epilogue_r">Lees verder</p>
            <ButtonArrow class="w-3 h-3 transform rotate-90"/>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import ButtonArrow from "../ui/ButtonArrow.vue"
export default {
  props: ['item','card_id'],
  components: {
    ButtonArrow
  },
  data(){
    return{
      isOverflow: false
    }
  },
  mounted(){
    this.$nextTick(() => {
      const textWrapper = document.getElementById('text_wrapper_num_'+this.card_id)
      if(textWrapper){
        if(textWrapper.scrollHeight > textWrapper.clientHeight){
            this.isOverflow = true
        }else{
          this.isOverflow = false
        }
      }else{
      const textWrapper = document.getElementById('text_wrapper_num_mob_'+this.card_id)
        if(textWrapper.scrollHeight > textWrapper.clientHeight){
          this.isOverflow = true
        }else{
          this.isOverflow = false
        }
      }
    });
  },
  updated(){
    // const textWrapper = document.getElementById('text_wrapper_num_'+this.card_id)
    //   if(textWrapper){
    //     if(textWrapper.scrollHeight > textWrapper.clientHeight){
    //         this.isOverflow = true
    //     }else{
    //       this.isOverflow = false
    //     }
    //   }else{
    //   const textWrapper = document.getElementById('text_wrapper_num_mob_'+this.card_id)
    //     if(textWrapper.scrollHeight > textWrapper.clientHeight){
    //       this.isOverflow = true
    //     }else{
    //       this.isOverflow = false
    //     }
    //   }
  },
  methods:{
    extendCardHeight(slideNumber){
      const parentSlide = '#text_wrapper_num_'+slideNumber
      const gradient = '#gradient_num_'+slideNumber

      this.gsap.timeline()
      .to(parentSlide,.1,{
        overflow: "auto"
      })
      .to(gradient,.2,{
        autoAlpha: 0
      },"-=.1")
      .to(parentSlide, {
        duration: .5,
        scrollTo: {
          y: parentSlide,
          offsetY: -80
        }
      },"-=.1");
    }
  }
}
</script>

<style scoped>
.text-rich-text{
  color: white !important;
}
.text-gradient{
  display: flex;
  background: linear-gradient(180deg, rgba(62, 171, 74, 0) -37.56%, #3EAB4A 59.45%);
}
</style>
