<template>
  <div>

    <!--
    <div class="pt-8">
      <div class="rounded-md p-6 text-gray-900 bg-gradient-to-t from-gray-400 to-gray-300" v-if="globals.section_tool">
        <h1>
          Boilerplate
        </h1>
        <h3 class=" bg-green-900 p-2 px-4 inline-block rounded-full text-gray-300 mr-3">
          Vue 3
        </h3>
        <h3 class=" bg-indigo-800 p-2 px-4 inline-block rounded-full text-gray-300 mr-3">
          Tailwind 2
        </h3>
      </div>
    </div>
    -->

    <Page_view :obj="pageObject" />

  </div>
</template>

<script>
import Page_view from '@/components/Page_view'

var page_dest, page_src, crumbs

export default {
  components: {
    Page_view
  },
  data() {
    return {
      pageObject: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    page_dest = to.path
    next((vm) => vm.getRoute())
  },
  beforeRouteUpdate(to, from, next) {
    page_dest = to.path
    this.getRoute()
    next()
  },
  mounted(){
  },
  methods:{
    getRoute() {
        this.emitter.emit('loaded_page')

				page_src = null
				let page_routing = page_dest.split("/")
				crumbs = page_routing.filter(function(x) {
					return x !== ""
				})
				let have_route = false

        //console.log('crumbs:')
        //console.log(crumbs)

        if(this.globals.data.pages.hasOwnProperty(crumbs[0])){
          have_route = true
					page_src = this.globals.data.pages[crumbs[0]]
          if(page_src.parent){
            have_route = false
          }
        }

        if(this.globals.data.pages.hasOwnProperty(crumbs[1])){
          have_route = true
					page_src = this.globals.data.pages[crumbs[1]]
        }

				if (!crumbs[0]) {
					console.log('home')
					have_route = true
					page_src = this.globals.data.pages['home']
				}
				if (!page_src) {
					have_route = false
				}
				if (!have_route) {
					page_src = this.globals.data.pages['404']
				}

        // home replace
        let parent_cur, child_cur
        if (!crumbs[0]) {
					console.log('replace home')
          parent_cur = Object.keys(this.globals.data.pages).find(key => this.globals.data.pages[key].year === this.globals.data.date_info.current_year)
          //console.log(parent_cur)
          if(parent_cur){
            child_cur = this.globals.data.pages[parent_cur].blocks[0].content[Number(this.globals.data.date_info.current_week_number) - 1]
            //console.log(parent_cur, child_cur)
            page_src = this.globals.data.pages[child_cur]
          }
        }

        this.globals.page_cur = page_src
        this.pageObject = JSON.parse(JSON.stringify(page_src))
        //console.log(page_src)
        
    }
  }
}
</script>

<style scoped>
</style>
